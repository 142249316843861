import React, { Component } from 'react'
import Styled from 'styled-components'
import Recaptcha from 'react-google-recaptcha'
import { graphql } from 'gatsby'
import { navigateTo } from 'gatsby-link'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BGcontainer from '../components/backgroundImage'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin: auto;
  background: rgba(255, 255, 255, .6);
  .headerContainer{
    width: 100%;
  }
  input, textarea{
    width: 100%;
    margin-bottom: 20px;
    border: none;
    padding: 0 10px;
    outline: none;
  }
  input{
    height: 45px;
  }
  textarea{
    min-height: 100px;
  }
  button{
    width: 100%;
    border: none;
    color: #fff;
    background: #e06a03;
    height: 45px;
    outline: none;
    transition: opacity .3s ease-in-out;
    cursor: pointer;
    &:disabled{
      opacity: .5;
      cursor: not-allowed;
    }
  }
  .recaptcha{
    margin-bottom: 20px;
  }
  @media(max-width: 801px){
    padding-top: 70px;
    .headerContainer, button, input, textarea{
    width: calc(100% - 40px);
    }
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactUs extends Component {
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
    document.getElementById('submit').removeAttribute('disabled')
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO title="Contact Us" />
        <BGcontainer fluid={data.imageSharp.fluid}>
          <Container>
            <div className="headerContainer">
              <h2>Your website is closer than you think. Contact us so that we can get started. </h2>
            </div>
            <form
              name="contact"
              method="post"
              action="/thank-you"
              data-netlify-recaptcha="true"
              data-netlify="true"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="text" name="name" placeholder="Name*" required onChange={this.handleChange} />
              <input type="email" name="email" placeholder="Email*" required onChange={this.handleChange} />
              <textarea name="comments" placeholder="Message*" required onChange={this.handleChange}></textarea>
              <Recaptcha
                ref="recaptcha"
                sitekey={RECAPTCHA_KEY}
                onChange={this.handleRecaptcha}
                className="recaptcha"
              />
              <button type="submit" id="submit" disabled>
                Submit
              </button>
            </form>
          </Container>
        </BGcontainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query ContactUsQuery {
    imageSharp(fluid: { originalName: { eq: "contactBG.jpg" } }) {
      fluid {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`
