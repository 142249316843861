import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BGSection = styled(BackgroundImage)`
  padding-top: 100px;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  min-height: 100vh;
`

export default BGSection
